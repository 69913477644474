<template>
    <div class="error-not-found">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="error-inner text-center">
                        <div class="image">
                            <img src="../assets/img/404.png" alt="404">
                        </div>
                        <h1 class="heading-h3 text-white">Parece que você está perdido.</h1>
                        <div class="error-text">
                            <p class="text-white">Parece que nada foi encontrado neste local. Você pode voltar para o última página ou vá para a página inicial.</p>
                            <div class="error-button-group">
                                <router-link class="btn-submit bg-wht" to="/">
                                    Anterior
                                </router-link>
                                <router-link class="btn-submit" to="/">
                                   Homepage
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>